import { useRoutes, Navigate } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import GlobalStyles from './components/GlobalStyles';
import theme from './assets/theme';
import Modal from './components/Modals';
import AddCustomers from './views/Customers/Add';
import Customers from './views/Customers/List';
import DashboardLayout from './components/Layout/DashboardLayout';
import Login from './views/Auth/Login';
import NotFound from './views/404';

const App = () => {
  const token = useSelector((state) => state.authReducer.token);
  // let dashboard = '';

  if (token) {
    const decodedToken = jwtDecode(token);

    // if (decodedToken.role === 'Admin') {
    //   dashboard = '/app/category';
    // }
    // if (decodedToken.role === 'Manager') {
    //   dashboard = '/app/employee_title';
    // }
    // if (decodedToken.role === 'Supervisor') {
    //   dashboard = '/app/class';
    // }
  }

  const protectedRoutes = [
    {
      element: <DashboardLayout />,
      children: [
        { path: '/app/customers', element: <Customers /> },
        { path: '/app/customers/create', element: <AddCustomers /> },
        { path: '*', element: <Navigate to="/app/customers" /> },
      ],
    },
  ];

  const publicRoutes = [
    {
      // element: <MainLayout />,
      children: [
        { path: '', element: <Navigate to="/login" /> },
        { path: '/', element: <Login /> },
        { path: '/login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/login" /> },
      ],
    },
  ];

  const content = useRoutes(token ? protectedRoutes : publicRoutes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Modal />
        {content}
        <GlobalStyles />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
