import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  // Typography,
} from '@mui/material';
import {
  BarChart as BarChartIcon,
  Folder as FolderIcon,
  FileMinus as FileMinusIcon,
  Hash as HashIcon,
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  ShoppingCart as ShoppingCartIcon,
  LogOut as LogOutIcon,
  MessageSquare as MessageSquareIcon,
  Trello as TrelloIcon,
  Database as DatabaseIcon,
  GitBranch as BranchIcon,
  Sliders as BannerIcon,
} from 'react-feather';
import AddLocationTwoToneIcon from '@mui/icons-material/AddLocationTwoTone';
import AssistantPhotoTwoToneIcon from '@mui/icons-material/AssistantPhotoTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';

import { useTranslation } from 'react-i18next';
import Item from './Item';

const Sidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const sideMenuItems = [
    // {
    //   href: '/app/dashboard',
    //   icon: BarChartIcon,
    //   title: t('statistics'),
    // },
    {
      href: '/app/locations',
      icon: AddLocationTwoToneIcon,
      title: t('locations'),
    },
    {
      href: '/app/staff_title',
      icon: AssistantPhotoTwoToneIcon,
      title: t('staff_title'),
    },
    {
      href: '/app/staffs',
      icon: AccountCircleTwoToneIcon,
      title: t('staffs'),
    },
    {
      href: '/app/packages',
      icon: ListAltOutlinedIcon,
      title: t('packages'),
    },
    {
      href: '/app/class',
      icon: GroupOutlinedIcon,
      title: t('class'),
    },
    {
      href: '/app/rooms',
      icon: MeetingRoomIcon,
      title: t('rooms'),
    },
    {
      href: '/app/customers',
      icon: BranchIcon,
      title: t('customers'),
    },
    {
      href: '/app/course',
      icon: FileMinusIcon,
      title: t('course'),
    },
    {
      href: '/app/course_schedule',
      icon: TableRowsRoundedIcon,
      title: t('course_schedule'),
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, []);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#2A455F',
      }}
    >
      {/* <Divider /> */}
      <Box sx={{ p: 2 }}>
        <List>
          {sideMenuItems.map((item) => (
            <Item
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 85,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: '85px',
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

Sidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

Sidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default Sidebar;
