export function dataSelector(dataResponse) {
  const filtered = dataResponse.data.map((item, idx) => ({
    id: item.id,
    item,
    country: item.country,
    name: item.fullName,
    phone: item.phoneNumber,
    company: item.company,
    photo: item.photo,
    address: item.address,
    status: item.status,
    created_time:
      (new Date(item.updatedTime).getDate() < 10
        ? '0' + new Date(item.updatedTime).getDate()
        : new Date(item.updatedTime).getDate()) +
      '/' +
      (new Date(item.updatedTime).getMonth() < 10
        ? '0' + (new Date(item.updatedTime).getMonth() + 1)
        : new Date(item.updatedTime).getMonth() + 1) +
      '/' +
      new Date(item.updatedTime).getFullYear(),
  }));
  return { total: dataResponse.total, data: filtered };
}
