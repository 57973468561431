import axios from 'axios';
// import { useDispatch } from 'react-redux';
import auth from './auth';

const baseURL = process.env.REACT_APP_BASE_URL;
const lang = localStorage.getItem('i18nextLng');
const service = axios.create({
  baseURL,
  headers: {
    'X-TenantID': 'public',
    'Content-Type': 'application/json',
  },
});

service.interceptors.response.use(
  (res) => res.data,
  (error) => {
    console.log(error);
    if (error.response.status === 401 || error.response.status === 403) {
      const refreshToken = localStorage.getItem('refresh_token');
      const data = { refresh_token: refreshToken };

      auth
        .refreshToken(data)
        .then((resp) => {
          console.log(resp);
          if (resp.status === 200) {
            if (resp.data.error) {
              localStorage.removeItem('token');
              localStorage.removeItem('refresh_token');
              window.location.href('/login');
            } else {
              localStorage.setItem('token', resp.data.access_token);
              localStorage.setItem('refresh_token', resp.data.refresh_token);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          window.location.href = '/login';
        });
    }
    return Promise.reject(error);
  }
);

service.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export function execute(promise) {
  return new Promise((resolve, reject) => {
    promise
      .then((response) => {
        // console.log(response.data);
        resolve(response.data);
      })
      .catch(reject);
  });
}

export { service, lang };
