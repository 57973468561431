import React from 'react';
import UserCell from './CellUser';
import StatusCell from './CellStatus';
import AbsentCell from './CellAbsent';
import GenderCell from './CellGender';

export const headerMaker = (data, handler) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  data
    .filter(({ show }) => show)
    .map(({ type, ...rest }) => {
      if (type === 'user') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <UserCell value={value} />,
        };
      }
      if (type === 'status' || type === 'state') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <StatusCell value={value} />,
        };
      }
      if (type === 'attendance') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => (
            <AbsentCell value={value} handler={handler} />
          ),
        };
      }
      if (type === 'gender') {
        // eslint-disable-next-line react/react-in-jsx-scope
        return {
          ...rest,
          // eslint-disable-next-line react/jsx-props-no-spreading
          Cell: ({ cell: { value } }) => <GenderCell value={value} />,
        };
      }

      return rest;
    });
