import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearToken } from '../../../redux/modules/auth/actions';

const NavItem = ({ href, icon: Icon, title, logout, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const handleLogOut = () => {
    localStorage.removeItem('token');
    dispatch(clearToken());
  };

  // const active = href ? !!matchPath({ path: href }, location.pathname) : false;
  const active = location.pathname.startsWith(href);

  return (
    <Tooltip title={title} placement="right" arrow>
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0,
        }}
        {...rest}
      >
        <Button
          component={RouterLink}
          onClick={logout ? handleLogOut : null}
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              color: 'primary.active',
              '&:after': {
                content: '" "',
                position: 'absolute',
                top: 0,
                left: '-12px',
                height: '100%',
                width: '3px',
                backgroundColor: 'primary.active',
              },
            }),
            '& svg': {
              mr: 1,
            },
          }}
          to={href}
        >
          {Icon && <Icon size="20" />}
        </Button>
      </ListItem>
    </Tooltip>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
