import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Box } from '@mui/material';
import {
  Container,
  Content,
  Header,
  Title,
  CloseButton,
  CloseIcon,
  Body,
} from '../GlobalStyles/modals';

export default ({ title, modal = false, handleModal, body }) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  return (
    <Dialog
      hideBackdrop={true}
      fullWidth={fullWidth}
      open={modal}
      onClose={handleModal}
      maxWidth={maxWidth}
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <Body overflow="auto">{body}</Body>
    </Dialog>
  );
};
