import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Chip,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
// import InputIcon from '@mui/icons-material/Input';
import Logo from '../../Logo';
import LogoIcon from '../../Logo/logo';
import SelectLanguageBtn from '../../Language';
import ProfileBtn from '../../Profile';

const Navbar = ({ onMobileNavOpen, ...rest }) => {
  const [notifications] = useState([]);
  const matches = useMediaQuery('(min-width:1200px)');
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 22,
      top: 13,
      border: '0.5px solid',
      padding: '0 1px',
      fontSize: 10,
      color: 'red',
    },
  }));

  return (
    <AppBar
      elevation={1}
      {...rest}
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <Toolbar>
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        {!matches ? (
          <Hidden>
            <IconButton onClick={onMobileNavOpen} size="small">
              <MenuIcon
                sx={{
                  color: 'darker.default',
                }}
              />
            </IconButton>
          </Hidden>
        ) : (
          <></>
        )}
        <Box sx={{ p: 1 }} />
        <Hidden>
          <RouterLink to="/">{!matches ? <LogoIcon /> : <Logo />}</RouterLink>
        </Hidden>

        <Box sx={{ flexGrow: 8 }} />
        {/* <Hidden>
          <IconButton size="small">
            <StyledBadge badgeContent={4} color="warning" variant="dot">
              <NotificationsIcon />
            </StyledBadge>
          </IconButton>
        </Hidden> */}
        {/* <Hidden>
          <SelectLanguageBtn />
        </Hidden> */}
        <Hidden>
          <Chip
            size="small"
            sx={{ height: 25 }}
            label={decodedToken.role.replace('_', ' ').toUpperCase()}
            color="primary"
          />
        </Hidden>
        <Box sx={{ p: 1 }} />
        <Hidden>
          <ProfileBtn />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default Navbar;
