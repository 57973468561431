import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navbar from '../../NavBars/DashboardNavbar';
import { Container, Content, Root } from '../style';
import { Wrapper, Main } from './style';
import Sidebar from '../../Sidebar';
import SidebarWithIcon from '../../SidebarWithIcon';

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const matches = useMediaQuery('(min-width:1200px)');

  return (
    <Root>
      <Navbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <Sidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Wrapper>
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Wrapper>
    </Root>
  );
};

export default DashboardLayout;
