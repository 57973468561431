import forms from './actions/form';
import customers from './actions/customers';
import auth from './actions/auth';
import modals from './actions/modals';
import store from './actions/store';
import application from './actions/application';
import lang from './actions/lang';

export default {
  ...forms,
  ...auth,
  ...store,
  ...customers,
  ...modals,
  ...application,
  ...lang,
};
