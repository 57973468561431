import { service } from '.';

export default {
  getAll: (query) => service.get(`/customers?${query}`),
  getOne: (id) => service.get(`/customers/${id.id}`),
  create: (data) => service.post('/customers/register', data),
  update: (id, data) => service.patch(`/customers/${id.id}`, data),
  changeStatus: (id, status) =>
    service.patch(`/customers/update_status/${id}/${status}`),
};
