import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { CardMedia } from '@mui/material';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { deepOrange } from '@mui/material/colors';
import jwtDecode from 'jwt-decode';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { setLanguage } from '../../redux/modules/lang/actions';
import { clearToken } from '../../redux/modules/auth/actions';
import auth from '../../services/auth';
import usersApi from '../../services/users';

export default () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [previewImg, setPreviewImg] = useState();
  const image = useSelector((state) => state.storeReducer.image);

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const handleLogOut = () => {
    const data = {};
    data.refresh_token = localStorage.getItem('refresh_token');
    dispatch(clearToken());
    navigate('/login');

    // auth
    //   .removeToken(data)
    //   .then((resp) => {
    //     if (resp.status === 200 || resp.status === 204) {
    //       dispatch(clearToken());
    //       navigate('/login');
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  // const active = href ? !!matchPath({ path: href }, location.pathname) : false;
  const active = location.pathname.startsWith('#');
  const paths = location.pathname;

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeLang = (item) => {
    dispatch(setLanguage(item));
  };

  const loadingData = () => {
    usersApi
      .getOne(decodedToken.user_id)
      .then((res) => {
        if (res.photo) {
          setPreviewImg(res.photo);
        }
      })
      .catch((err) => {
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    // loadingData();
  }, [image]);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <div>
      {previewImg ? (
        <CardMedia
          onClick={handleClick}
          component="img"
          style={{
            width: 40,
            height: 40,
            borderRadius: 5,
            objectFit: 'fill',
          }}
          image={previewImg}
          alt=""
        />
      ) : (
        <Avatar
          onClick={handleClick}
          sx={{ borderRadius: 5, bgcolor: '#E5E8EC' }}
        >
          <AccountCircleIcon sx={{ borderRadius: 5, bgcolor: '#000' }} />
          {/* <PersonOutlineIcon onClick={handleClick} /> */}
        </Avatar>
      )}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* {paths === '/app/account' || paths === '/app/settings' ? (
          <MenuItem
            key="home"
            onClick={() => {
              handleClose();
              navigate(
                localStorage.getItem('last_page')
                  ? localStorage.getItem('last_page')
                  : '/app/dashboard'
              );
            }}
          >
            <HomeIcon />
            {t('home')}
          </MenuItem>
        ) : (
          <MenuItem
            key="account"
            onClick={() => {
              handleClose();
              navigate('/app/account');
            }}
          >
            <PersonIcon />
            {t('account')}
          </MenuItem>
        )} */}
        <MenuItem
          key="exit"
          onClick={() => {
            handleClose();
            handleLogOut();
          }}
        >
          <LogoutIcon />
          {t('exit')}
        </MenuItem>
      </StyledMenu>
    </div>
  );
};
