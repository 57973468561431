import { takeLatest, put, delay } from 'redux-saga/effects';
import types from '../../../constants/action-types';
import service from '../../../services/customers';
import { setData, setError, setTotal, setLoading } from './actions';
import { dataSelector } from './selectors';

function* fetchData({ payload }) {
  try {
    if (payload.isSearch) yield delay(500);
    // yield put(setLoading(true));
    if (payload.query !== 'page=1&page_size=10') {
      const res = yield service.getAll(payload.query);

      const { data, total } = dataSelector(res);
      yield put(setError(''));
      yield put(setData(data));
      yield put(setTotal(total));
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setError(error.message));
  }
}

function* onSearch({ payload }) {
  yield delay(500);
  console.log(payload);
}

export default function* customersSaga() {
  yield takeLatest(types.TABLE_CUSTOMERS_FETCH_DATA, fetchData);
  yield takeLatest(types.TABLE_CUSTOMERS_SAGA_ONSEARCH_ACTION, onSearch);
}
