import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Container, Circle, CircleContent, Content } from './style';

export default ({ value }) => {
  return (
    <>
      {value ? (
        <Container>
          <CheckCircleIcon color="success" />
        </Container>
      ) : (
        <Container>
          <CheckCircleIcon color="error" />
        </Container>
      )}
    </>
  );
};
