import types from '../../../constants/action-types';

const defaultState = {
  token: localStorage.getItem('token'),
  refresh_token: localStorage.getItem('refresh_token'),
  // user: localStorage.getItem('user'),
};

const map = {
  [types.SET_TOKEN]: (state, { payload }) => ({
    ...state,
    token: payload.token.access_token,
    refresh_token: payload.token.refresh_token,
  }),
  [types.SET_VERIFY]: (state, { payload }) => ({
    ...state,
    code: payload.code,
    phone: payload.phone,
    access_token: payload.token.access_token,
  }),
  [types.CLEAR_TOKEN]: (state) => ({
    ...state,
    token: undefined,
    refresh_token: undefined,
    phone: undefined,
    access_token: undefined,
  }),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) =>
  (map[action.type] && map[action.type](state, action)) ||
  state ||
  defaultState;
