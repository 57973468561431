import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export function useQuery({ fetchData }) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.langsReducer.language);
  // const languageId = useSelector((state) => state.langsReducer.language_id);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const statusQuery = useMemo(
    () => (status ? `&status=${status}` : ''),
    [status]
  );

  // const locationIdQuery = useMemo(
  //   () =>
  //     locationId !== 'all' && locationId ? `&location_id=${locationId}` : '',
  //   [locationId]
  // );

  // const locationIdQuery = useMemo(
  //   () =>
  //     locationId !== 'all' && locationId ? `&locations=${locationId}` : '',
  //   [locationId]
  // );

  // const paginationQuery = useMemo(
  //   () => (pageSize ? `&page_size=${pageSize}` : ''),
  //   [pageSize]
  // );

  const paginationQuery = useMemo(
    () => `page=${search ? 0 : pageIndex}&size=${pageSize}`,
    [pageIndex, pageSize, status, search]
  );

  const query = useMemo(
    () => `${paginationQuery}${statusQuery}`,
    [paginationQuery, statusQuery]
  );

  const handleOnTableChange = ({ pageIndex, pageSize }) => {
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  useEffect(() => {
    dispatch(
      fetchData({
        lng: language,
        isSearch: true,
        query: `${query}${search ? '&search=' + search : ''}`,
      })
    );

    // eslint-disable-next-line
  }, [dispatch, search, language]);

  useEffect(() => {
    if (pageSize > 0) {
      dispatch(
        fetchData({
          lng: language,
          isSearch: false,
          query: `${query}${search ? `&search=${search}` : ''}`,
        })
      );
    }
    // eslint-disable-next-line
  }, [query, dispatch, language]);

  return {
    query,
    search,
    status,
    pageIndex,
    pageSize,
    setSearch,
    setStatus,
    handleOnTableChange,
  };
}
