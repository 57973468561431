import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  Snackbar,
  Stack,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { Filter as FilterIcon, Search as SearchIcon } from 'react-feather';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/Loading';
import { toolTips } from './helper';
import { useStyles } from './style';
import Table from '../../../components/Table';
import { customersHeader } from '../../../redux/modules/table/common';
import { headerMaker } from '../../../components/Table/helper';
import { fetchData } from '../../../redux/modules/customers/actions';
import { setTotals } from '../../../redux/modules/store/actions';
import FilterTools from '../../../components/FilteredBody/Subcategory';
import AnimationDiv from '../../../components/Animations/Transition';
import { useQuery } from '../../useQuery';
import ModalWindow from '../../../components/Modals';
import ChangeStatusModal from '../../../components/Modals/ConfirmStatus';
import customerApi from '../../../services/customers';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({ ...rest }) => {
  const classes = useStyles();
  const {
    query,
    search,
    status,
    setSearch,
    setStatus,
    handleOnTableChange,
    setLocationId,
  } = useQuery({ fetchData });

  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const { data, total } = useSelector((state) => state.customersReducer);

  localStorage.setItem('total_data', total);
  const language = useSelector((state) => state.langsReducer.language);
  const headers = useMemo(
    () => headerMaker(customersHeader),
    [customersHeader]
  );
  const [loading, setLoading] = useState(false);
  const customerItem = useSelector((state) => state.customersReducer.item);
  const [statusModal, setStatusModal] = useState(false);

  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const curr = addHoursToDate(new Date(), 5);
  const first = curr.getDate();
  const last = first - 6;

  const addCustomer = () => {
    navigate('/app/customers/create');
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: -1,
    },
    validationSchema,
  });

  useEffect(() => {
    // dispatch(setTotals(total));
    // loadingData();
  }, [language]);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleStatusModal = () => {
    setStatusModal(!statusModal);
  };

  const changeStatus = () => {
    setLoading(true);

    customerApi
      .changeStatus(customerItem.id, !customerItem.status)
      .then((resp) => {
        setStatusModal(!statusModal);
        setLoading(false);
        setSearch(customerItem.fullName);
        setSearch('');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  return (
    <>
      <Helmet>
        <title> {t('customers')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 1,
        }}
      >
        <Container maxWidth={false}>
          <ModalWindow
            title={t('change_status')}
            modal={statusModal}
            handleModal={handleStatusModal}
            maxWidth="40%"
            body={
              <ChangeStatusModal
                handleModal={handleStatusModal}
                changeStatus={changeStatus}
                yes={t('yes')}
                cancel={t('cancel')}
                text={t('ask_change_status')}
              />
            }
          />
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <Loading loading={loading} />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            mb={4}
            mt={2}
          >
            {t('customers')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9} sm={12}>
              <div className={classes.navFilterSection}>
                <TextField
                  className={classes.search}
                  size="small"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('search')}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={3} sm={12}>
              <div className={classes.topBtns}>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: '#FFFFFF',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addCustomer}
                  // color="info"
                >
                  {t('add').toUpperCase()}
                </Button>
              </div>
            </Grid>
          </Grid>
          <AnimatePresence className={classes.filters}>
            {isShow ? (
              <AnimationDiv>
                <FilterTools status={status} setStatus={setStatus} />
              </AnimationDiv>
            ) : null}
          </AnimatePresence>
          <Box sx={{ pt: 5 }}>
            <div className={classes.root}>
              <Card {...rest}>
                <Table
                  data={data}
                  headers={headers}
                  toolTips={toolTips}
                  onChange={handleOnTableChange}
                  modal={handleModal}
                  statusModal={handleStatusModal}
                />
              </Card>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};
