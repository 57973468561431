export const scienceHeader = [
  {
    Header: 'science',
    accessor: 'name',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    type: 'status',
    show: true,
  },
];

export const categoryHeader = [
  {
    Header: 'name',
    accessor: 'item',
    show: true,
    type: 'user',
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    type: 'status',
    show: true,
  },
];

export const subcategoryHeader = [
  {
    Header: 'subcategory_name',
    accessor: 'name',
    show: true,
  },
  {
    Header: 'category_name',
    accessor: 'category_name',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    type: 'status',
    show: true,
  },
];

export const customersHeader = [
  {
    Header: 'customer',
    accessor: 'item',
    type: 'user',
    show: true,
  },
  {
    Header: 'company',
    accessor: 'company',
    show: true,
  },
  {
    Header: 'phone',
    accessor: 'phone',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    type: 'status',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
];

export const controlsHeader = [
  {
    Header: 'customer',
    accessor: 'customer',
    type: 'user',
    show: true,
  },
  {
    Header: 'attendance',
    accessor: 'absent',
    type: 'attendance',
    show: true,
  },
];

export const examsHeader = [
  {
    Header: 'name',
    accessor: 'name',
    show: true,
  },
  {
    Header: 'class',
    accessor: 'class',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    type: 'status',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
];

export const classHeader = [
  {
    Header: 'class_name',
    accessor: 'class_name',
    show: true,
  },
  {
    Header: 'class_duration',
    accessor: 'class_duration',
    show: true,
  },
  {
    Header: 'price',
    accessor: 'price',
    show: true,
  },
  {
    Header: 'hours',
    accessor: 'hours',
    show: true,
  },
  {
    Header: 'begin_date',
    accessor: 'begin_date',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    type: 'status',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
];

export const bookingHeader = [
  {
    Header: 'service_name',
    accessor: 'service_name',
    show: true,
  },
  {
    Header: 'duration',
    accessor: 'service_duration',
    show: true,
  },
  {
    Header: 'hours',
    accessor: 'hours',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    type: 'status',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
];

export const roomsHeader = [
  {
    Header: 'name',
    accessor: 'room_name',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    type: 'status',
    show: true,
  },
];

export const locationsHeader = [
  {
    Header: 'name',
    accessor: 'location_name',
    show: true,
  },
  {
    Header: 'region',
    accessor: 'region',
    show: true,
  },
  {
    Header: 'district',
    accessor: 'district',
    show: true,
  },
  {
    Header: 'street',
    accessor: 'street',
    show: true,
  },
  {
    Header: 'description',
    accessor: 'notes',
    show: true,
  },
  {
    Header: 'status',
    accessor: 'status',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
];

export const ordersHeader = [
  {
    Header: 'customer',
    accessor: 'customer',
    type: 'user',
    show: true,
  },
  {
    Header: 'order_number',
    accessor: 'order_number',
    show: true,
  },
  {
    Header: 'all_amount',
    accessor: 'all_amount',
    show: true,
  },
  {
    Header: 'residue_amount',
    accessor: 'amount',
    show: true,
  },
  {
    Header: 'payment_state',
    accessor: 'state',
    type: 'state',
    show: true,
  },
  {
    Header: 'service_type',
    accessor: 'service_type',
    show: true,
  },
  {
    Header: 'service_name',
    accessor: 'service_name',
    show: true,
  },
  {
    Header: 'payment_date',
    accessor: 'payment_date',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
];

export const ordersHistoryHeader = [
  {
    Header: 'customer',
    accessor: 'customer',
    type: 'user',
    show: true,
  },
  {
    Header: 'order_number',
    accessor: 'order_number',
    show: true,
  },
  {
    Header: 'amount',
    accessor: 'amount',
    show: true,
  },
  {
    Header: 'payment_state',
    accessor: 'state',
    type: 'state',
    show: true,
  },
  {
    Header: 'payment_type',
    accessor: 'payment_type',
    show: true,
  },
  {
    Header: 'service_type',
    accessor: 'service_type',
    show: true,
  },
  {
    Header: 'service_name',
    accessor: 'service_name',
    show: true,
  },
  {
    Header: 'payment_date',
    accessor: 'payment_date',
    show: true,
  },
  {
    Header: 'created_time',
    accessor: 'created_time',
    show: true,
  },
];
