import React, { useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import {
  ListItemButton,
  Collapse,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearToken } from '../../../redux/modules/auth/actions';

const NavItem = ({
  index,
  href,
  icon: Icon,
  title,
  logout,
  subitemsclub,
  subitemspayment,
  ...rest
}) => {
  const location = useLocation();

  const { t } = useTranslation();
  const [isOpenClubInfo, setIsOpenClubInfo] = useState(false);
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const dispatch = useDispatch();

  const theme = useTheme();

  const handleColapse = (index) => {
    if (index === 3) {
      setIsOpenClubInfo(!isOpenClubInfo);
      setIsOpenPayment(false);
    }
    if (index === 6) {
      setIsOpenClubInfo(false);
      setIsOpenPayment(!isOpenPayment);
    }

    if (index === 3) {
      setIsOpenClubInfo(!isOpenClubInfo);
      if (isOpenClubInfo) {
        setIsOpenClubInfo(false);
      } else {
        setIsOpenClubInfo(true);
      }
    }
    if (index === 6) {
      setIsOpenPayment(!isOpenPayment);
      if (isOpenClubInfo) {
        setIsOpenClubInfo(false);
      } else {
        setIsOpenClubInfo(true);
      }
    }
  };

  const handleLogOut = () => {
    localStorage.removeItem('token');
    dispatch(clearToken());
  };

  // const active = href ? !!matchPath({ path: href }, location.pathname) : false;
  const active = location.pathname.startsWith(href);
  const activeClubInfo =
    location.pathname.startsWith('/app/category') ||
    location.pathname.startsWith('/app/subcategory') ||
    location.pathname.startsWith('/app/banners') ||
    location.pathname.startsWith('/app/packages') ||
    location.pathname.startsWith('/app/class') ||
    location.pathname.startsWith('/app/science') ||
    location.pathname.startsWith('/app/lesson_plan') ||
    location.pathname.startsWith('/app/exam') ||
    location.pathname.startsWith('/app/controls') ||
    location.pathname.startsWith('/app/tests');

  const activePayment =
    location.pathname.startsWith('/app/payment_details') ||
    location.pathname.startsWith('/app/payment_history');

  if (subitemsclub || subitemspayment) {
    return (
      <>
        {subitemsclub && (
          <div>
            <ListItemButton
              key={index}
              onClick={() => handleColapse(index)}
              disableGutters
              // component={RouterLink}
              sx={{
                paddingLeft: 0.5,
                top: 3,
                bottom: 3,
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3,
                color: '#fff',
                fontWeight: 'medium',
                letterSpacing: 0,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                ...(activeClubInfo && {
                  color: '#E17436',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  '&:after': {
                    content: '" "',
                    position: 'absolute',
                    top: 0,
                    left: '-4px',
                    height: '100%',
                    width: '4px',
                    backgroundColor: '#E17436',
                  },
                }),
                '& svg': {
                  mr: 1,
                },
              }}
              {...rest}
              to={href}
            >
              {Icon && <Icon size="20" />}
              <ListItemText primary={t(title)} />

              <ListItemIcon
                sx={{
                  justifyContent: 'flex-end',
                  color: '#fff',
                }}
              >
                {isOpenClubInfo ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </ListItemIcon>
            </ListItemButton>
            <Grid container>
              <Grid item xs={2} md={1} sm={2}>
                <Divider
                  sx={{
                    height: '100%',
                    width: '0px',
                    backgroundColor: '#fff',
                    color: '#fff',
                    position: 'relative',
                    left: '12px',
                  }}
                  orientation="vertical"
                />
              </Grid>
              <Grid item xs={10} md={11} sm={10}>
                <Collapse
                  in={isOpenClubInfo}
                  // timeout="auto"
                  // unmountOnExit
                  sx={{ paddingLeft: theme.spacing(10) + 30, width: '100%' }}
                >
                  {subitemsclub.map((childrenLink) => (
                    <ListItemButton
                      onClick={() => {
                        if (
                          childrenLink.href !== '/app/account' &&
                          childrenLink.href !== '/app/settings'
                        ) {
                          localStorage.setItem('last_page', childrenLink.href);
                        }
                      }}
                      key={childrenLink.index}
                      component={RouterLink}
                      disableGutters
                      sx={{
                        top: 3,
                        p: 0,
                        pl: 0.5,
                        color: '#fff',
                        borderTopRightRadius: 3,
                        borderBottomRightRadius: 3,
                        fontWeight: 'medium',
                        // justifyContent: 'flex-start',
                        letterSpacing: 0,
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                        ...(location.pathname.startsWith(childrenLink.href) && {
                          color: '#E17436',
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          '&:after': {
                            content: '" "',
                            position: 'absolute',
                            top: 0,
                            left: '-4px',
                            height: '100%',
                            width: '4px',
                            backgroundColor: '#E17436',
                          },
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          },
                        }),
                        '& svg': {
                          mr: 1,
                        },
                      }}
                      {...rest}
                      to={childrenLink.href}
                    >
                      <childrenLink.icon>
                        <childrenLink.icon />
                      </childrenLink.icon>
                      <ListItemText
                        sx={{
                          py: 1,
                          pl: 0.5,
                        }}
                        primary={t(childrenLink.title)}
                      />
                    </ListItemButton>
                  ))}
                </Collapse>
              </Grid>
            </Grid>
          </div>
        )}
        {subitemspayment && (
          <div>
            <ListItemButton
              key={index}
              onClick={() => handleColapse(index)}
              disableGutters
              // component={RouterLink}
              sx={{
                display: 'flex',
                color: '#fff',
                pl: 0.5,
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3,
                top: 3,
                fontWeight: 'medium',
                justifyContent: 'flex-start',
                letterSpacing: 0,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                ...(activePayment && {
                  color: '#E17436',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  '&:after': {
                    content: '" "',
                    position: 'absolute',
                    top: 0,
                    left: '-4px',
                    height: '100%',
                    width: '4px',
                    backgroundColor: '#E17436',
                  },
                }),
                '& svg': {
                  mr: 1,
                },
              }}
              {...rest}
              to={href}
            >
              {Icon && <Icon size="20" />}
              <ListItemText sx={{}} primary={t(title)} />

              <ListItemIcon
                sx={{
                  justifyContent: 'flex-end',
                  color: '#fff',
                }}
              >
                {isOpenPayment ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </ListItemIcon>
            </ListItemButton>
            <Grid container>
              <Grid item xs={2} md={1} sm={2}>
                <Divider
                  sx={{
                    height: '100%',
                    width: '0px',
                    backgroundColor: '#fff',
                    color: '#fff',
                    position: 'relative',
                    left: '12px',
                  }}
                  orientation="vertical"
                />
              </Grid>
              <Grid item xs={10} md={11} sm={10}>
                <Collapse
                  in={isOpenPayment}
                  // timeout="auto"
                  // unmountOnExit
                  sx={{ paddingLeft: theme.spacing(10) + 30, width: '100%' }}
                >
                  {subitemspayment.map((childrenLink) => (
                    <ListItemButton
                      onClick={() => {
                        if (
                          childrenLink.href !== '/app/account' &&
                          childrenLink.href !== '/app/settings'
                        ) {
                          localStorage.setItem('last_page', childrenLink.href);
                        }
                      }}
                      key={childrenLink.index}
                      component={RouterLink}
                      disableGutters
                      sx={{
                        top: 3,
                        borderTopRightRadius: 3,
                        borderBottomRightRadius: 3,
                        py: 0,
                        pl: 1,
                        color: '#fff',
                        fontWeight: 'medium',
                        letterSpacing: 0,
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                        ...(location.pathname.startsWith(childrenLink.href) && {
                          color: '#E17436',
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          '&:after': {
                            content: '" "',
                            position: 'absolute',
                            top: 0,
                            left: '-4px',
                            height: '100%',
                            width: '4px',
                            backgroundColor: '#E17436',
                          },
                        }),
                        '& svg': {
                          mr: 1,
                        },
                      }}
                      {...rest}
                      to={childrenLink.href}
                    >
                      <childrenLink.icon>
                        <childrenLink.icon />
                      </childrenLink.icon>
                      <ListItemText
                        sx={{
                          py: 1.0,
                          pl: 1,
                        }}
                        primary={t(childrenLink.title)}
                      />
                    </ListItemButton>
                  ))}
                </Collapse>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }
  return href === '/login' ? (
    <ListItemButton
      key={index}
      onClick={() => handleLogOut()}
      disableGutters
      sx={{
        display: 'flex',
        pl: 0.5,
        top: 3,
        color: '#fff',
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        fontWeight: 'medium',
        justifyContent: 'flex-start',
        letterSpacing: 0,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        ...(active && {
          color: '#E17436',
          '&:after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: '-4px',
            height: '100%',
            width: '4px',
            backgroundColor: '#E17436',
          },
        }),
        '& svg': {
          mr: 1,
        },
      }}
      {...rest}
    >
      {Icon && <Icon size="20" />}
      <ListItemText primary={t(title)} />
    </ListItemButton>
  ) : (
    <ListItemButton
      key={index}
      component={RouterLink}
      onClick={() => {
        if (href !== '/app/account' && href !== '/app/settings') {
          localStorage.setItem('last_page', href);
        }
      }}
      disableGutters
      sx={{
        paddingLeft: 0.5,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        color: '#fff',
        // color: 'text.secondary',
        fontWeight: 'medium',
        justifyContent: 'flex-start',
        letterSpacing: 0,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        ...(active && {
          color: '#E17436',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          '&:after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: '-15px',
            height: '100%',
            width: '3px',
            backgroundColor: '#E17436',
          },
        }),
        '& svg': {
          mr: 1,
        },
      }}
      {...rest}
      to={href}
    >
      {Icon && <Icon size="20" />}
      <ListItemText
        sx={{
          color: '#fff',
          // color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: '#E17436',
            '&:after': {
              content: '" "',
              position: 'absolute',
              top: 0,
              left: '-4px',
              height: '100%',
              width: '4px',
              backgroundColor: '#E17436',
            },
          }),
          '& svg': {
            mr: 1,
          },
        }}
        primary={t(title)}
      />
    </ListItemButton>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  subitems: PropTypes.array,
};

export default NavItem;
