import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  TextField,
  FormHelperText,
  Typography,
  Grid,
} from '@mui/material';
import InputMask from 'react-input-mask';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useStyles } from './style';
import auth from '../../../services/auth';
import { setToken } from '../../../redux/modules/auth/actions';
import LBSIcon from '../../../assets/icons/logo.png';

const Login = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isInvalid, setIsInvalid] = useState(false);
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    username: Yup.string().max(255).required(t('required')),
    password: Yup.string().min(4).max(255).required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const data = {};
      data.username = values.username;
      data.password = values.password;

      auth
        .getAdminToken(data)
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            dispatch(setToken(resp.data));
          }
        })
        .catch((err) => {
          console.log(err);
          setIsInvalid(true);
        });
    },
  });

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 4 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={LBSIcon} alt="" width={50} />
              </div>
            </Box>
            {isInvalid && (
              <Box sx={{ mb: 4 }} display="flex" justifyContent="center">
                <Typography variant="body1" color="error">
                  Invalid username or password
                </Typography>
              </Box>
            )}
            <Grid container my={2}>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  Username
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  error={Boolean(
                    formik.touched.username && formik.errors.username
                  )}
                  fullWidth
                  helperText={formik.touched.username && formik.errors.username}
                  placeholder={t('Enter username')}
                  name="username"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.username}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.black}
                >
                  Password
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  error={Boolean(
                    formik.touched.password && formik.errors.password
                  )}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  placeholder={t('Enter password')}
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            {/* <Grid display="flex" justifyContent="right">
              <Typography
                component={Link}
                to="/resetPassword"
                mt={1}
                sx={{
                  cursor: 'pointer',
                  color: 'rgba(22, 22, 22, 0.6) ',
                  transition: 'all 0.3s ease-in-out',

                  '&:hover': {
                    color: 'rgba(22, 22, 22, 1)',
                  },
                }}
              >
                {t('forgot_password')}
              </Typography>
            </Grid> */}

            <Box sx={{ pt: 5 }}>
              <Button
                // disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
              >
                Login
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
