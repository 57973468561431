import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    paddingTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  input: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 200,
    padding: 2,
  },
  clearFilter: {
    position: 'absolute',
    top: 20,
    right: 0,
  },
}));
