import { Box, Drawer, Hidden, List } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BarChart as BarChartIcon,
  LogOut as LogOutIcon,
  GitBranch as BranchIcon,
} from 'react-feather';
import jwtDecode from 'jwt-decode';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ScienceIcon from '@mui/icons-material/Science';
import { useStyles } from './style';
import Item from './Item';

export default ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const classes = useStyles();
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const lastPage = localStorage.getItem('last_page');

  const adminClubInfo = [
    {
      index: 4,
      href: '/app/category',
      icon: AccountCircleOutlinedIcon,
      title: 'category',
    },
    {
      index: 2,
      href: '/app/subcategory',
      icon: AssignmentIndOutlinedIcon,
      title: 'subcategory',
    },
    // {
    //   index: 3,
    //   href: '/app/science',
    //   icon: ScienceIcon,
    //   title: 'science',
    // },
    // {
    //   index: 5,
    //   href: '/app/rooms',
    //   icon: MeetingRoomIcon,
    //   title: 'rooms',
    // },
    // {
    //   index: 6,
    //   href: '/app/class',
    //   icon: ArticleOutlinedIcon,
    //   title: 'class',
    // },
    // {
    //   index: 7,
    //   href: '/app/lesson_plan',
    //   icon: EventNoteIcon,
    //   title: 'lesson_plan',
    // },
    // {
    //   index: 8,
    //   href: '/app/controls',
    //   icon: Brightness1Icon,
    //   title: 'controls',
    // },
    // {
    //   index: 9,
    //   href: '/app/exam',
    //   icon: FactCheckIcon,
    //   title: 'exam',
    // },
    // {
    //   index: 10,
    //   href: '/app/tests',
    //   icon: CheckBoxIcon,
    //   title: 'tests',
    // },
  ];

  const managerClubInfo = [
    {
      index: 1,
      href: '/app/category',
      icon: AssignmentIndOutlinedIcon,
      title: 'category',
    },
    {
      index: 2,
      href: '/app/science',
      icon: ScienceIcon,
      title: 'science',
    },
    {
      index: 3,
      href: '/app/employees',
      icon: AccountCircleOutlinedIcon,
      title: 'employee',
    },
    {
      index: 4,
      href: '/app/rooms',
      icon: MeetingRoomIcon,
      title: 'rooms',
    },
    {
      index: 5,
      href: '/app/class',
      icon: ArticleOutlinedIcon,
      title: 'class',
    },
    {
      index: 6,
      href: '/app/lesson_plan',
      icon: EventNoteIcon,
      title: 'lesson_plan',
    },
    {
      index: 7,
      href: '/app/controls',
      icon: Brightness1Icon,
      title: 'controls',
    },
    {
      index: 8,
      href: '/app/exam',
      icon: FactCheckIcon,
      title: 'exam',
    },
    {
      index: 9,
      href: '/app/tests',
      icon: CheckBoxIcon,
      title: 'tests',
    },
  ];

  const supervisorClubInfo = [
    {
      index: 2,
      href: '/app/class',
      icon: ArticleOutlinedIcon,
      title: 'class',
    },
  ];

  const paymentInfo = [
    {
      index: 16,
      href: '/app/payment_details',
      icon: InfoOutlinedIcon,
      title: 'payment_details',
    },
    {
      index: 17,
      href: '/app/payment_history',
      icon: HistoryOutlinedIcon,
      title: 'payment_history',
    },
  ];

  const [sideMenuItems, setSideMenu] = useState([
    // {
    //   index: 1,
    //   href: '/app/dashboard',
    //   icon: BarChartIcon,
    //   title: 'statistics',
    // },
    {
      index: 2,
      href: lastPage,
      icon: ReportGmailerrorredIcon,
      title: 'manage',
      childrenclub: managerClubInfo,
    },
    {
      index: 3,
      href: '/app/customers',
      icon: BranchIcon,
      title: 'customers',
    },
  ]);

  const adminSideMenu = [
    // {
    //   index: 1,
    //   href: '/app/dashboard',
    //   icon: DashboardOutlinedIcon,
    //   title: 'statistics',
    // },
    // {
    //   index: 2,
    //   href: decodedToken.role === 'Admin' ? '/app/club/create' : lastPage,
    //   icon: InfoOutlinedIcon,
    //   title: 'register_center',
    // },
    // {
    //   index: 3,
    //   href: lastPage,
    //   icon: ManageAccountsIcon,
    //   title: 'manage',
    //   childrenclub: adminClubInfo,
    // },
    {
      index: 4,
      href: '/app/customers',
      icon: PeopleAltOutlinedIcon,
      title: 'customers',
    },
    // {
    //   index: 5,
    //   href: '/app/booking',
    //   icon: ListAltOutlinedIcon,
    //   title: 'booking',
    // },
    // {
    //   index: 6,
    //   href: lastPage,
    //   icon: AccountBalanceWalletOutlinedIcon,
    //   title: 'payments',
    //   childrenpayment: paymentInfo,
    // },
    // {
    //   index: 7,
    //   href: '/app/guide',
    //   icon: MenuBookIcon,
    //   title: 'guide',
    // },
  ];

  const managerSideMenu = [
    {
      index: 1,
      href: lastPage,
      icon: InfoOutlinedIcon,
      title: 'manage',
      childrenclub: managerClubInfo,
    },
    {
      index: 2,
      href: '/app/customers',
      icon: PeopleAltOutlinedIcon,
      title: 'customers',
    },
    {
      index: 3,
      href: '/app/booking',
      icon: ListAltOutlinedIcon,
      title: 'booking',
    },
    {
      index: 4,
      href: lastPage,
      icon: AccountBalanceWalletOutlinedIcon,
      title: 'payments',
      childrenpayment: paymentInfo,
    },
    // {
    //   index: 5,
    //   href: '/app/guide',
    //   icon: MenuBookIcon,
    //   title: 'guide',
    // },
  ];

  const supervisorSideMenu = [
    {
      index: 1,
      href: '/app/class',
      icon: InfoOutlinedIcon,
      title: 'register_center',
    },
  ];

  const accounts = [
    {
      index: 12,
      href: '/app/account',
      icon: PersonIcon,
      title: 'account',
    },
    // {
    //   index: 13,
    //   href: '/app/settings',
    //   icon: SettingsIcon,
    //   title: 'settings',
    // },
    {
      index: 14,
      href: '/login',
      icon: LogOutIcon,
      title: 'exit',
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    } else if (
      location.pathname === '/app/account' ||
      location.pathname === '/app/settings'
    ) {
      setSideMenu(accounts);
    } else {
      setSideMenu(adminSideMenu);
      // if (decodedToken.role === 'Admin') {

      // }
      // if (decodedToken.role === 'Manager') {
      //   setSideMenu(managerSideMenu);
      // }
      // if (decodedToken.role === 'Supervisor') {
      //   setSideMenu(supervisorSideMenu);
      // }
    }
  }, [location]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#2A455F',
        position: 'relative',
        height: openMobile ? 'calc(100% - 0px)' : 'calc(100% - 130px)',
        '&::-webkit-scrollbar': {
          width: 5,
        },
        '&::-webkit-scrollbar-track': {
          // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgrey',
          // outline: '1px solid slategrey',
          borderRadius: 2,
        },
      }}
      style={{
        // border: '1px solid black',
        overflow: 'auto',
        overflowY: 'scroll', // added scroll
      }}
    >
      <Box sx={{ pl: 0.5, paddingTop: 1 }}>
        <div className={classes.root}>
          <List>
            {sideMenuItems.map((item) => (
              <Item
                index={item.index}
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                subitemsclub={item.childrenclub}
                subitemspayment={item.childrenpayment}
              />
            ))}
          </List>
        </div>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 238,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: '238px',
              top: 64,
              height: 'calc(100% + 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};
