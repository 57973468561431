import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import {
  Box,
  CardMedia,
  colors,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  OutlinedInput,
  Divider,
  Chip,
  Modal,
  Snackbar,
  Stack,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import jwtDecode from 'jwt-decode';
import { styled, useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import Loading from '../../../components/Loading';
import ManySelect from '../../../components/FormElements/ManySelect';
import filesService from '../../../services/uploadFile';
import customers from '../../../services/customers';
import { useStyles } from './style';
import UploadIcon from '../../../assets/icons/upload.svg';
import { IOSSwitch, Alert } from '../../../components/GlobalStyles';

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const { t } = useTranslation();
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showPassword, setshowPassword] = useState(false);
  const [isNavigate, setIsNavigate] = useState(true);
  const [confirmationShowPassword, setConfirmationShowPassword] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState('');

  const [uploadFile, setUploadFile] = useState('');
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
    company: Yup.string().required(t('required')),
    username: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      username: '',
      email: '',
      company: '',
      password: null,
      confirmation_password: null,
      address: '',
      notes: '',
      expire_time: null,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        fullName: values.name,
        email: values.email,
        photo: uploadFile,
        phoneNumber: values.phone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', ''),
        password: values.password,
        address: values.address,
        username: values.username,
        company: values.company,
        expireTime: values.expire_time,
        status,
        notes: values.notes,
      };

      if (state) {
        if (
          values.password &&
          values.password !== '' &&
          values.password !== values.confirmation_password
        ) {
          formik.setSubmitting(false);
          setSnackbarMessage(t('error_input_password'));
          setOpen(true);
          return;
        }
        if (
          (values.password && values.password.length < 8) ||
          (values.password && values.confirmation_password.length < 8)
        ) {
          formik.setSubmitting(false);
          setSnackbarMessage(t('invalid_password_count_8'));
          setOpen(true);
        } else {
          setLoading(true);
          customers
            .update(state, data)
            .then((res) => {
              setLoading(false);
              if (isNavigate) {
                navigate('/app/customers');
              }
            })
            .catch((err) => {
              setLoading(false);
              formik.setSubmitting(false);
              setSnackbarMessage(t(err.response.data.message));
              setOpen(true);
            });
        }
      } else {
        if (values.password !== values.confirmation_password) {
          formik.setSubmitting(false);
          setSnackbarMessage(t('error_input_password'));
          setOpen(true);
          return;
        }

        if (
          values.password.length < 8 ||
          values.confirmation_password.length < 8
        ) {
          formik.setSubmitting(false);
          setSnackbarMessage(t('invalid_password_count_8'));
          setOpen(true);
        } else {
          setLoading(true);
          customers
            .create(data)
            .then((res) => {
              setLoading(false);
              navigate('/app/customers');
            })
            .catch((err) => {
              setLoading(false);
              formik.setSubmitting(false);
              setSnackbarMessage(t(err.response.data.message));
              setOpen(true);
            });
        }
      }
    },
  });

  const uploadImage = (photos) => {
    const data = new FormData();
    data.append('files', photos);
    filesService
      .create('customers', data)
      .then((resp) => {
        setUploadFile(resp.files[0]);
        console.log(resp);
      })
      .catch((err) => console.log(err));
  };

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setPreviewImg(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
        uploadImage(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
      });
    }
  };

  const loadingData = () => {
    setLoading(true);
    if (state) {
      customers
        .getOne(state)
        .then((resp) => {
          formik.setValues({
            ...formik.values,
            name: resp.fullName,
            phone: resp.phoneNumber,
            email: resp.email,
            company: resp.company,
            username: resp.username,
            address: resp.address,
            expire_time: resp.expireTime,
            notes: resp.notes,
          });

          if (resp.photo) {
            setPreviewImg(resp.photo);
          }

          setStatus(resp.status);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadingData();
  }, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmationPassword = () => {
    setConfirmationShowPassword(!confirmationShowPassword);
  };

  const handleMouseDownConfirmationPassword = (event) => {
    event.preventDefault();
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('Updating');
      }
      return t('Update');
    }
    if (isSubmitting) {
      return t('Submitting');
    }
    return t('Submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Modal hideBackdrop open={openCrop} onClose={handleCropModal}>
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">{t('edit_image')}</h2>
            <div style={{ width: '100%' }}>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleCropModal()}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                }
                onClick={() => {
                  getCropData();
                  handleCropModal();
                }}
              >
                {t('ready')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {state ? t('edit_customer') : t('add_customer')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={3} xs={12}>
            <Box
              sx={{
                py: 2,
              }}
            >
              <CardMedia
                component="img"
                style={{
                  width: 210,
                  height: 210,
                  borderRadius: 5,
                  objectFit: 'fill',
                }}
                image={previewImg || UploadIcon}
                alt=""
              />
              <input
                id="selectFile"
                type="file"
                accept=".png,.jpg"
                style={{ display: 'none' }}
                onChange={imgSelectHandler.bind(this)}
              />
              <Box
                sx={{
                  py: 0.5,
                }}
              />
              <Button
                type="file"
                fullWidth
                size="small"
                variant="contained"
                sx={{
                  width: 210,
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
                color="error"
                onClick={() => {
                  document.getElementById('selectFile').click();
                }}
              >
                {previewImg ? t('change') : t('select_image')}
              </Button>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('full_name')} *
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="name"
                    autoComplete="namer"
                    type="text"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter full name')}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.name &&
                      formik.touched.name &&
                      formik.errors.name}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('company')} *
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="company"
                    autoComplete="company"
                    type="text"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter company')}
                    value={formik.values.company.toUpperCase()}
                    error={
                      formik.touched.company && Boolean(formik.errors.company)
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.company &&
                      formik.touched.company &&
                      formik.errors.company}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} my={1}>
                <Grid item md={6} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('phone')} *
                  </Typography>
                  <InputMask
                    className={classes.inputmask}
                    autoComplete="off"
                    size="small"
                    onChange={formik.handleChange}
                    name="phone"
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="+998 (_ _) _ _ _ - _ _ - _ _"
                    mask="+\9\98 (99) 999-99-99"
                    maskChar="_"
                    style={{
                      border: '1px solid #c4c4c4',
                      outline: '1px solid #F7F8FA',
                      paddingLeft: '14px',
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('email')}
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="email"
                    autoComplete="namer"
                    type="text"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter email')}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('username')} *
                  </Typography>
                  <TextField
                    disabled={state}
                    className={classes.search}
                    name="username"
                    autoComplete="namer"
                    type="text"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter username')}
                    value={formik.values.username}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.username &&
                      formik.touched.username &&
                      formik.errors.username}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('password')}
                  </Typography>
                  <OutlinedInput
                    className={classes.search}
                    name="password"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter password')}
                    value={formik.values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    size="small"
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 2,
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.password &&
                      formik.touched.password &&
                      formik.errors.password}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('confirmation_password')}
                  </Typography>
                  <OutlinedInput
                    className={classes.search}
                    name="confirmation_password"
                    fullWidth
                    type={confirmationShowPassword ? 'text' : 'password'}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter confirmation password')}
                    value={formik.values.confirmation_password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmationPassword}
                          onMouseDown={handleMouseDownConfirmationPassword}
                          edge="end"
                        >
                          {confirmationShowPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.confirmation_password &&
                      Boolean(formik.errors.confirmation_password)
                    }
                    size="small"
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 2,
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.confirmation_password &&
                      formik.touched.confirmation_password &&
                      formik.errors.confirmation_password}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12} my={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className={classes.search}
                    label="Expire Time"
                    value={formik.values.expire_time}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'expire_time',
                        new Date(e.$d).getTime()
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={12} xs={12} my={2}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('address')}
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="address"
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={t('enter address')}
                  value={formik.values.address}
                />
              </Grid>
              <Grid item xl={12} md={12} xs={12} my={2}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('notes')}
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="notes"
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={t('enter notes')}
                  value={formik.values.notes}
                />
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={() => navigate('/app/customers')}
                >
                  {t('cancel')}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => console.log()}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(state, formik.isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
